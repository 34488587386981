import { ActionIcon, Button, Group, Input, Navbar, ScrollArea, Switch } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconBooks, IconVariable, IconX } from '@tabler/icons';
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { LinksGroup } from '../../components/NavbarLinksGroup/NavbarLinksGroup';
import { ScriptContext } from "../context/ScriptContext";
import { mockdata } from './menuData';
import './navbar.css';
import { useStyles } from './style';
import { AppSettingsContext } from '../context/AppSettingsContext';
import ModalCustomNode from '../ModalCustomNode';
import { compareAppVersions } from '../../compareAppVersions';

const NavbarNested = (props) => {
    const text = useRef('')
    const ref = useRef(null)
    const { settings } = useContext(AppSettingsContext)
    const scriptContext = useContext(ScriptContext);
    const { classes } = useStyles();
    const customNodeRef = useRef(null);
    const [navItem, setNavItem] = useState(mockdata.map(item => ({ ...item, links: item.links.filter(link => link.data.version <= settings.autoVersion) })));
    const [show, setShow] = useState(false)
    const [filter, setFilter] = useState({ search: '', page: 1, limit: 9 });
    const [lastPage, setLastPage] = useState(1);
    const [category, setCategory] = useState('-1')
    const handleOpenNode = (index) => {
        const newState = [...navItem]
        newState[index].initiallyOpened = !newState[index].initiallyOpened
        setNavItem(newState)
    }

    useEffect(() => {
        setNavItem(mockdata.map(item => ({ ...item, links: item.links.filter(link => {
            console.log(link.data.appVersion);
            if (link.data.appVersion) {
                const appVersion = window.api?.APP_VERSION || ''
                return compareAppVersions(appVersion, link.data.appVersion) >= 0
            }
            return link.data.version <= settings.autoVersion
        }) })))
    },[settings.autoVersion])

    const links = useMemo(
        () => navItem.map(
            (item, index) => <LinksGroup {...item} 
                key={item.label}
                index={index} 
                handleOpenNode={handleOpenNode} 
                handleDbClick={props.handleDbClick}
            />
    ), [navItem]);
    const form = useForm({
        initialValues: { name: scriptContext.scriptName ?? '' },

        // functions will be used to validate values at corresponding key
        validate: {
            name: (value) => (value.length < 1
                ? 'Name must have at least 1 letters'
                : value.length > 255
                    ? 'Name is too long'
                    : null
            ),
        },
    });

    useEffect(() => {
        form.setValues({
            name: scriptContext.scriptName ?? "",
        })
    }, [scriptContext])

    const handleOpenAllNodes = () => {
        const newData = [...navItem]
        for ( const i of newData) {
            if (!ref.current.checked) {
                i.initiallyOpened = false
            } else {
                i.initiallyOpened = true
            }
        }
        if (ref.current.checked) {
            customNodeRef.current.setOpenCollapse(true)
        } else {
            customNodeRef.current.setOpenCollapse(false)
        }
        setNavItem(newData)
    }

   const handleClearInput = () => {
        text.current.value = ""
        setNavItem(mockdata.map(item => ({ ...item, links: item.links.filter(link => link.data.version <= settings.autoVersion) })))
   }

   const rightSection = (<ActionIcon onClick={handleClearInput} radius="xl"><IconX size="1rem"/></ActionIcon>)

   const handleFilter = (e) => {
        const text = e.target.value
        const newData = [...mockdata].map(item => ({
            ...item,
            links: item.links.filter(link => {
                console.log(link.data.appVersion);
                if (link.data.appVersion) {
                    const appVersion = window.api?.APP_VERSION || ''
                    return compareAppVersions(appVersion, link.data.appVersion) >= 0
                }
                return link.data.version <= settings.autoVersion
            })
        }))
        let node = newData.map((element) => {
            if (text.toLowerCase() === "") {
                return element
            } else {
                return {...element, links: element.links.filter((nodeLink) => 
                    nodeLink.data.label.toLowerCase().includes(text.toLowerCase())
                )}
            }
        })
        setNavItem(node)
   }

    return (
        <>
            <Navbar width={{ sm: 360 }} p="md" className={classes.navbar}>
                <Navbar.Section className={classes.header}>
                    <Group position="apart" align="center">
                        <Input
                            ref={text}
                            placeholder="Search"
                            rightSection={rightSection}
                            onChange={handleFilter}
                            />
                            <Switch className='d-flex' ref={ref} checked={navItem.some((item) => item.initiallyOpened)} onChange={handleOpenAllNodes} />
                    </Group>
                    <div style={{ marginTop: '20px'}}>
                        <Group position="apart" align="center">
                            <Button
                                leftIcon={<IconVariable size={14} />}
                                variant="default"
                                onClick={props.handleShowVariables}
                            >
                                Variables
                            </Button>
                            <Button
                                leftIcon={<IconBooks stroke={1.25} size={14}/>}
                                variant="default"
                                onClick={() => setShow(true)}
                            >
                                Library custom node
                            </Button>
                        </Group>
                    </div>
                </Navbar.Section>
                <Navbar.Section grow className={classes.links} component={ScrollArea}>
                    <div className={classes.linksInner}>
                        {links}
                        {/* <CustomNode ref={customNodeRef}/> */}
                    </div>
                </Navbar.Section>

                <Navbar.Section className={classes.footer}>
                </Navbar.Section>
            </Navbar>
            {show && <ModalCustomNode 
                show={show} 
                setShow={setShow}
                props={props}
                filter={filter}
                setFilter={setFilter}
                lastPage={lastPage}
                setLastPage={setLastPage}
                category={category}
                setCategory={setCategory}
            />}
        </>
    );
}

export default NavbarNested;