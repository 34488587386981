import {
  Button,
  Container,
  Input,
  Modal,
  Text,
  useMantineTheme,
  PasswordInput,
  Switch,
  SegmentedControl,
  Select
} from "@mantine/core";
import { useForm } from "@mantine/form";
import React, { useContext, useEffect, useState } from "react";
import { ScriptContext } from "../context/ScriptContext";
import { ListProfilesContext } from "../context/ListProfilesContext";
import ComponentInputSearch from "../../components/GlobalComponent/ComponentInputSearch";
import { AppSettingsContext } from "../context/AppSettingsContext";
import { getAllFolderByUser } from "../../request/Automation"
import { compareAppVersions } from "../../compareAppVersions"

const ModalStatus = ({
  show,
  setShowModal,
  handleSaveAs,
  handleError,
  handleSubmit,
  modalName,
  setUuidProfile,
  handleStart
}) => {
  const theme = useMantineTheme();
  const scriptContext = useContext(ScriptContext);
  const { userCharge } = useContext(AppSettingsContext)
  const { listProfile, valueChoose, setValueChoose, setSearch, isCloud, setIsCloud } = useContext(ListProfilesContext);
  const [profileModes, setProfileModes] = useState(['Cloud', 'Local'])
  useEffect(() => {
    if (userCharge?.current) {
      const result = []
      if (userCharge?.current?.profile) {
        result.push("Cloud")
      } else {
        setIsCloud(false)
      }
      if (userCharge?.current?.profile_local) {
        result.push("Local")
      }
      setProfileModes(result)
    }
  }, [userCharge])
  const form = useForm({
    initialValues: {
      name: scriptContext.scriptName ?? "",
      usingPassword: scriptContext?.scriptPassword ?? false,
      password: scriptContext?.password ?? "",
      folder_script_id: scriptContext?.folder_script_id??null
    },

    // functions will be used to validate values at corresponding key
    validate: {
      name: (value) =>
        value.length < 1
          ? "Name must have at least 1 letters"
          : value.length > 255
          ? "Name is too long"
          : null,
    },
  });

  const [usePassword, setUsePassword] = useState(scriptContext?.scriptPassword ?? false);

  const [changePassword, setChangePassword] = useState(false);

  const [saveAsPassword, setSaveAsPassword] = useState(false)

  const toggleUsePassword = () => {
    setUsePassword((prev) => !prev);
  };

  useEffect(() => {
    form.setFieldValue("usingPassword", usePassword);
  }, [usePassword]);

  const handleClose = () => {
    setShowModal(false);
  };

  const [allFolders, setAllFolders] = useState([])
  
  useEffect(()=>{
    const fnEffect = async ()=>{
      try {
        let response = await getAllFolderByUser()
        const appVersion = window.api?.APP_VERSION || ''
        if (compareAppVersions(appVersion, '4.0.14') == 1) {
          let options = response.data.data.content.all_folder.map((item) => {
            return {
              value: item.id,
              label: item.name
            };
          });
          setAllFolders([ {value: null, label: 'No folder'}, ...options])
        }else{
          let response = await getAllFolderByUser()
          let options = response.data.data.map((item) => {
            if (item.id == 0) {
              item.id = null
            }
            return {
              value: item.id,
              label: item.name
            };
          });
          setAllFolders(options)
        }
      } catch (error) {
        console.log(error.data.title??'error get folders');
      }
    }
    fnEffect()
  },[])

  const handleSetValue = (item) => {
    if (typeof item === "string") {
      setValueChoose(item);
      setSearch(item);
    } else if (typeof item === "object") {
      setValueChoose(item.label);
    }
    let browserName = listProfile.find((ele) => ele.value === item?.value);
    setUuidProfile({ uuid: item?.value, browser_name: browserName?.label });
  };

  return (
    <Modal
      zIndex={1000}
      opened={show}
      onClose={handleClose}
      overlayColor={
        theme.colorScheme === "dark"
          ? theme.colors.dark[9]
          : theme.colors.gray[2]
      }
      title={
        <Text fw={500} fz="xl">
          {modalName.title}
        </Text>
      }
      overlayOpacity={0.55}
      overlayBlur={0.5}
      size="lg"
    >
      <Container>
        {(() => {
          switch (modalName.type) {
            case "Save":
              return (
                <div style={{ marginBottom: "12px" }}>
                  <Input.Wrapper
                    label={modalName.label}
                    id="name-script"
                    withAsterisk
                  >
                    <Input
                      id="name-script"
                      name="name-script"
                      placeholder={modalName.placeholder}
                      {...form.getInputProps("name")}
                    />
                  </Input.Wrapper>
                  <Select
                    id="folder_script_id"
                    name="folder_script_id"
                    style={{marginTop:"12px"}}
                    label="Select folder to save"
                    placeholder="Pick value"
                    data={allFolders}
                    {...form.getInputProps("folder_script_id")}
                  />
                  <div
                    style={{
                      display: "flex",
                      marginTop: "15px",
                      columnGap: "10px",
                      alignItems: "center",
                      justifyContent: "space-between",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: "10px",
                      }}
                    >
                      <div>Using password</div>
                      <Switch
                        defaultChecked={usePassword}
                        onClick={() => {
                          toggleUsePassword();
                          setChangePassword(false);
                        }}
                        name="using-password"
                        style={{ paddingTop: "5px" }}
                      />
                    </div>
                    {usePassword && scriptContext.scriptPassword && (
                      <div
                        onClick={() => setChangePassword(true)}
                        className="change-password-btn"
                      >
                        Change password
                      </div>
                    )}
                  </div>
                  {((usePassword && !scriptContext.scriptPassword) ||
                    (changePassword && usePassword)) && (
                    <Input.Wrapper
                      label={"Password"}
                      id="password-script"
                      style={{ marginTop: "10px" }}
                    >
                      <PasswordInput
                        id="name-script"
                        name="name-script"
                        placeholder={"Enter password script"}
                        {...form.getInputProps("password")}
                      />
                    </Input.Wrapper>
                  )}
                  <form
                    style={{ textAlign: "end", marginTop: "20px" }}
                    onSubmit={form.onSubmit(handleSubmit, handleError)}
                  >
                    <Button type="submit" variant="outline">
                      Save
                    </Button>
                  </form>
                </div>
              );
            case "Start":
              return (
                <div style={{ marginBottom: "12px" }}>
                  {
                    !!userCharge.current?.profile_local && (
                      <SegmentedControl
                        style={{ marginBottom: 12 }}
                        data={profileModes}
                        value={ isCloud ? 'Cloud' : 'Local' }
                        onChange={(value) => setIsCloud(value === 'Cloud')}
                      />
                    )
                  }
                  <ComponentInputSearch
                    listProfile={listProfile}
                    placeholder="Choose a profile"
                    label={modalName.label}
                    withAsterisk={true}
                    handleData={(e) => handleSetValue(e)}
                    dataState={valueChoose}
                    setUuidProfile={setUuidProfile}
                  />
                  <div style={{ textAlign: "end", marginTop: "20px" }}>
                    <Button
                      onClick={handleStart}
                      variant="outline"
                      color="teal"
                    >
                      Start
                    </Button>
                  </div>
                </div>
              );
            case "SaveAs":
              return (
                <div style={{ marginBottom: "12px" }}>
                  <Input.Wrapper
                    label={modalName.label}
                    id="name-script"
                    withAsterisk
                  >
                    <Input
                      id="name-script"
                      name="name-script"
                      placeholder={modalName.placeholder}
                      {...form.getInputProps("name")}
                    />
                  </Input.Wrapper>
                  <Select
                    id="folder_script_id"
                    name="folder_script_id"
                    style={{marginTop:"12px"}}
                    label="Select folder to save"
                    placeholder="Pick value"
                    data={allFolders}
                    {...form.getInputProps("folder_script_id")}
                  />
                  <div
                    style={{
                      display: "flex",
                      marginTop: "15px",
                      columnGap: "10px",
                      alignItems: "center",
                      justifyContent: "space-between",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: "10px",
                      }}
                    >
                      <div>Using password</div>
                      <Switch
                        defaultChecked={saveAsPassword}
                        onClick={()=>setSaveAsPassword(prev=>!prev)}
                        name="using-password"
                        style={{ paddingTop: "5px" }}
                      />
                    </div>
                  </div>
                  {
                    saveAsPassword && 
                    <Input.Wrapper
                      label={"Password"}
                      id="password-script"
                      style={{ marginTop: "10px" }}
                    >
                      <Input
                        id="name-script"
                        type="password"
                        name="name-script"
                        placeholder={"Enter password script"}
                        {...form.getInputProps("password")}
                      />
                    </Input.Wrapper>
                  }
                  <form
                    style={{ textAlign: "end", marginTop: "20px" }}
                    onSubmit={form.onSubmit(handleSaveAs, handleError)}
                  >
                    <Button type="submit" variant="outline">
                      SaveAs
                    </Button>
                  </form>
                </div>
              );
            default:
              return null;
          }
        })()}
      </Container>
    </Modal>
  );
};
export default ModalStatus;
 