import { useEffect, useState } from "react";
import VariableTextarea from "../../../GlobalComponent/VariableTextarea";
import { Select } from "@mantine/core";
import VariableSelectWrite from "../../../GlobalComponent/VariableSelectWrite";
import VariableSelectCreate from "../../../GlobalComponent/VariableSelectCreate";

const MODELS = [
    { label: "Hidemium Gemini (Free)", value: "HidemiumGemini" },
    { label: "Gemini", value: "Gemini" },
    { label: "Open AI", value: "OpenAi" },
    { label: "Anthropic", value: "Anthropic" },
    { label: "DeepSeek-V3", value: "DeepSeek-V3" },
    { label: "DeepSeek-R1", value: "DeepSeek-R1" },
    { label: "Ollama", value: "Ollama" },
]

function AiAction(props) {
    const [dataState, setDataState] = useState({
        prompt: props?.nodeData?.data?.options?.prompt ?? '',
        agent: props?.nodeData?.data?.options?.agent ?? 'HidemiumGemini',
        apiKey: props?.nodeData?.data?.options?.apiKey ?? '',
        timeout: props?.nodeData?.data?.options?.timeout ?? '',
        output: props?.nodeData?.data?.options?.output ?? '',
    })
    const onChangeValue = (key) => (event) => {
        let value = dataState[key];
        if (event.type === 'change') {
            value = event.target.value
        } else if (event.type === 'click') {
            value = value + "${" + event.target.textContent + "}";
        } else if (typeof event === 'string') {
            value = event
        } 
        setDataState({...dataState, [key]: value})
    }

    useEffect(() => {
        props.handleSetDataBaseModal(dataState)
    }, [props, dataState])

    return (
        <div>
            <Select
                value={dataState.agent}
                label="Model"
                data={MODELS}
                onChange={onChangeValue('agent')}
                style={{ flex: 1 }}
            />
            {
                dataState.agent !== "HidemiumGemini" && (
                    <VariableTextarea
                        dataState={dataState.apiKey}
                        label="API Key"
                        handleData={onChangeValue('apiKey')}
                        handleSelect={onChangeValue('apiKey')}
                    />
                )
            }
            <VariableTextarea
                dataState={dataState.prompt}
                label="Prompt"
                handleData={onChangeValue('prompt')}
                handleSelect={onChangeValue('prompt')}
            />
            <VariableSelectCreate
                label="Output"
                value={dataState.output}
                handleChange={onChangeValue('output')}
                style={{ marginTop: '8px' }}
            />
            <VariableSelectWrite
                label="Timeout waiting"
                dataState={dataState.timeout}
                handleData={onChangeValue('timeout')}
                handleSelect={onChangeValue('timeout')}
            />
        </div>
    )
}

export default AiAction;
